export default [
  {
    url: '/dashboard/checklist',
    name: 'Dashboard Registration',
    i18n: 'navbar.checklist'
  },
  {
    url: '/apps/preventiveHealth',
    name: "Maternity registration",
    slug: "health-maternity-registration",
    i18n: "navbar.healthPlan",
  },
  {
    url: '/apps/preventiveHealth/documentCenter',
    name: "Document Center",
    slug: "health-document-Center",
    i18n: "navbar.documentCenter",
  }
]

