<template>
  <div class="header-wrapper">
    <h1 class="logo-text" v-if="organization.id">
      <img src="@/assets/images/logo/1.png" :alt="organization.orgName">
    </h1>
    <h1 class="logo-text" v-else>
<!--      Clinic-->
      <img :src="companyLogo" width="80" alt="">
    </h1>
    <h-nav-menu
      :class="[{'text-base': true}]"
      :navMenuItems="navMenuItems" />

    <div class="header-buttons">
      <div class="flex gap-x-8">
        <vs-dropdown vs-custom-content vs-trigger-click>
          <vs-icon icon="translate" class="py-6 px-3" size="34px"></vs-icon>
          <vs-dropdown-menu style="width: 180px">
            <div class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('en')">
              <img src="/flags/en.png" width="40" alt="">
              <p>English</p>
            </div>
            <div v-if="company.custom_french" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('fr')">
              <img src="/flags/fr.png" width="40" alt="">
              <p>French</p>
            </div>
            <div v-if="company.custom_arabic" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('uae')">
              <img src="/flags/uae.png" width="40" alt="">
              <p>Arabic</p>
            </div>
            <div v-if="company.custom_vietnamese" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('vt')">
              <img src="/flags/vt.png" width="40" alt="">
              <p>Vietnamese</p>
            </div>
            <div v-if="company.custom_russian" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('ru')">
              <img src="/flags/ru.png" width="40" alt="">
              <p>Russian</p>
            </div>
            <div v-if="company.custom_spanish" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('spanish')" >
              <img src="/flags/spanish.png" width="40" alt="">
              <p>Spanish</p>
            </div>
            <div v-if="company.custom_catalan" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('cat')" >
              <img src="/flags/cat.jpg" width="40" alt="">
              <p>Catalan</p>
            </div>
          </vs-dropdown-menu>
        </vs-dropdown>

        <vs-dropdown vs-custom-content vs-trigger-click >
          <div class="maternity-registration-btn user-profile-button">
            <button class="generic-button">
<!--              <font-awesome-icon icon="user" />-->
              <vs-icon icon="person" size="34px"></vs-icon>
            </button>
          </div>

          <vs-dropdown-menu style="width: 200px">
            <div v-if="getcanSee">
              <div class="flex gap-x-3 items-center w-full cursor-pointer hover:bg-grey-light px-4 py-2" @click="$router.push('/user/profile')">
                <vs-icon icon="account_circle" size="36px"></vs-icon>
                <p>Go to profile</p>
              </div>
              <div class="flex gap-x-3 items-center w-full mt-2 cursor-pointer hover:bg-grey-light px-4 py-2 bg-grey-light" @click="logout">
                <vs-icon icon="logout" size="36px"></vs-icon>
                <p>Logout</p>
              </div>
            </div>
            <div v-else>
              <div class="flex gap-x-3 items-center w-full cursor-pointer hover:bg-grey-light px-4 py-2" @click="$router.push('/pages/loginEmailLink')">
                <vs-icon icon="input" size="36px"></vs-icon>
                <p>Login</p>
              </div>
              <div class="flex gap-x-3 items-center w-full mt-2 cursor-pointer hover:bg-grey-light px-4 py-2" @click="$router.push('/pages/register')">
                <vs-icon icon="person_add" size="36px"></vs-icon>
                <p>Register</p>
              </div>
            </div>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

<!--      <div class="maternity-registration-btn">-->
<!--        <button class="generic-button">Registration</button>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import Bookmarks            from './components/Bookmarks.vue'
import I18n                 from './components/I18n.vue'
import SearchBar            from './components/SearchBar.vue'
import CartDropDown         from './components/CartDropDown.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown      from './components/ProfileDropDown.vue'
import Logo                 from '../Logo.vue'
import { Role } from '@/model/role';
import HNavMenu            from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import navMenuItems        from '@/layouts/components/vertical-nav-menu/navMenuItems.js'

export default {
  name: 'the-navbar-horizontal',
  props: {
    logo: { type: String                                                                                                          },
    navbarType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      navMenuItems
    }
  },
  components: {
    Logo,
    Bookmarks,
    I18n,
    SearchBar,
    CartDropDown,
    NotificationDropDown,
    ProfileDropDown,
    HNavMenu
  },
  computed: {
    navbarColor () {
      let color = '#fff'
      if (this.navbarType === 'sticky') color = '#f7f7f7'
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = '#f7f7f7'
        }
      }

      if (this.isThemedark === 'dark') {
        if (color === '#fff') {
          color = '#10163a'
        } else {
          color = '#262c49'
        }
      }

      return color
    },
    getcanSee() {
      // return this.$store.state.AppActiveUser.roleId === Role.user;
      return this.$store.state.auth.userProfile;
    },
    isThemedark ()          { return this.$store.state.theme                                                                       },
    // navbarStyle ()          { return this.navbarType === 'static' ? {transition: 'all .25s ease-in-out'} : {}                      },
    // navbarClasses ()        { return this.scrollY > 5 && this.navbarType === 'static' ? null : 'd-theme-dark-light-bg shadow-none' },
    scrollY ()              { return this.$store.state.scrollY                                                                     },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth                                                        },
    windowWidth ()          { return this.$store.state.windowWidth                                                                 },
    organization() {
      return this.$store.state.appCurrentOrganization;
    },
    companyLogo() {
      return this.$store.state.settings.custom_logo_link_s3
    },
    company() {
      return this.$store.state.settings.organizationDetails
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout');
    },
    setLocale(locale) {
      this.$i18n.locale = locale
      console.log("locale ", this.$i18n.locale)
    }
  }
}

</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.header-wrapper {
  //padding: 25px 35px;
  padding: 0 35px;
  max-width: 1400px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  box-shadow: none !important;
  margin: 0 auto;

  //.header-wrapper {
  //  width: 50%;
  //  display: flex;
  //  justify-content: space-between;
    .header-buttons {
      display: flex;
      .user-profile-button {
        margin-right: 20px;
        button {
          background: $white;
          color: var(--lGreen);
          //border: 2px solid var(--lGreen);
          //border-radius: 50%;
          width: 59px;
          height: 59px;
          position: relative;
          cursor: pointer;
          svg {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -15px;
            margin-top: -15px;
          }
        }
      }
    }
  //}

  .logo-text {
    //color: $green;
    color: var(--lGreen);
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 700;
    //transform: translate(-50%, 0);
  }
  button {
    outline: none;
    border-style: none;
  }
}

</style>
