<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <div class="header">
        <div class="mobile-menu-wrapper">
          <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />
        </div>
        <div class="header-wrapper">
          <h1 class="logo-text" @click="$router.push('/')">
<!--            Clinic-->
            <img :src="companyLogo"  width="60" height="40" class="object-cover" alt="">
          </h1>
          <div>
            <div class="header-buttons">
              <div class="flex gap-x-8 items-center h-full">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <vs-icon icon="translate" class="py-2 px-3" size="22px"></vs-icon>
                  <vs-dropdown-menu style="width: 180px">
                    <div class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('en')">
                      <img src="/flags/en.png" width="40" alt="">
                      <p>English</p>
                    </div>
                    <div v-if="company.custom_french" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('fr')">
                      <img src="/flags/fr.png" width="40" alt="">
                      <p>French</p>
                    </div>
                    <div v-if="company.custom_arabic" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('uae')">
                      <img src="/flags/uae.png" width="40" alt="">
                      <p>Arabic</p>
                    </div>
                    <div v-if="company.custom_vietnamese" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('vt')">
                      <img src="/flags/vt.png" width="40" alt="">
                      <p>Vietnamese</p>
                    </div>
                    <div v-if="company.custom_russian" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('ru')">
                      <img src="/flags/ru.png" width="40" alt="">
                      <p>Russian</p>
                    </div>
                    <div v-if="company.custom_spanish" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('spanish')" >
                      <img src="/flags/spanish.png" width="40" alt="">
                      <p>Spanish</p>
                    </div>
                    <div v-if="company.custom_catalan" class="w-full p-2 flex gap-x-3 cursor-pointer" @click="setLocale('cat')" >
                      <img src="/flags/cat.jpg" width="40" alt="">
                      <p>Catalan</p>
                    </div>
                  </vs-dropdown-menu>
                </vs-dropdown>

                <vs-dropdown vs-custom-content vs-trigger-click >
                  <vs-icon icon="person" size="24px"  class="py-2"></vs-icon>

                  <vs-dropdown-menu>
                    <div v-if="getcanSee">
                      <div class="flex gap-x-3 items-center w-full cursor-pointer hover:bg-grey-light px-4 py-2" @click="$router.push('/user/profile')">
                        <vs-icon icon="account_circle" size="24px"></vs-icon>
                        <p>Go to profile</p>
                      </div>
                      <div class="flex gap-x-3 items-center w-full mt-2 cursor-pointer hover:bg-grey-light px-4 py-2" @click="logout">
                        <vs-icon icon="logout" size="24px"></vs-icon>
                        <p>Logout</p>
                      </div>
                    </div>
                    <div v-else>
                      <div class="flex gap-x-3 items-center w-full cursor-pointer hover:bg-grey-light px-4 py-2" @click="$router.push('/pages/loginEmailLink')">
                        <vs-icon icon="input" size="24px"></vs-icon>
                        <p>Login</p>
                      </div>
                      <div class="flex gap-x-3 items-center w-full mt-2 cursor-pointer hover:bg-grey-light px-4 py-2" @click="$router.push('/pages/register')">
                        <vs-icon icon="person_add" size="24px"></vs-icon>
                        <p>Register</p>
                      </div>
                    </div>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>

              <!--      <div class="maternity-registration-btn">-->
              <!--        <button class="generic-button">Registration</button>-->
              <!--      </div>-->
            </div>
          </div>
<!--          <div class="maternity-registration-btn">-->
<!--            <button class="generic-button">Maternity Registration</button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Bookmarks            from './components/Bookmarks.vue'
import I18n                 from './components/I18n.vue'
import SearchBar            from './components/SearchBar.vue'
import CartDropDown         from './components/CartDropDown.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown      from './components/ProfileDropDown.vue'

export default {
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    Bookmarks,
    I18n,
    SearchBar,
    CartDropDown,
    NotificationDropDown,
    ProfileDropDown
  },
  computed: {
    navbarColorLocal () {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
    },
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor () {
      return {'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj () {
      if      (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      else if (this.verticalNavMenuWidth)               return 'navbar-full'
    },
    companyLogo() {
      return this.$store.state.settings.custom_logo_link_s3
    },
    getcanSee() {
      // return this.$store.state.AppActiveUser.roleId === Role.user;
      return this.$store.state.auth.userProfile;
    },
    company() {
      return this.$store.state.settings.organizationDetails
    }
  },
  methods: {
    showSidebar () {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    },
    logout () {
      this.$store.dispatch('auth/logout');
    },
    setLocale(locale) {
      this.$i18n.locale = locale
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.header {
  margin: 0 auto;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: none !important;
  @media (min-width: 1200px) {
    padding: 5rem 10rem 0;
  }

  .header-wrapper {
    width: 50%;
    display: flex;
    justify-content: space-between;
  }

  .logo-text {
    color: $green;
    text-transform: uppercase;
    font-size: 42px;
    font-weight: 700;
    transform: translate(-50%, 0);
  }
  button {
    outline: none;
    border-style: none;
  }
  .generic-button {
    cursor: pointer;
    border-radius: 10px;
    text-transform: uppercase;
    min-width: max-content;
    padding: 8px 20px;
    color: #FFFFFF;
  }
  .maternity-registration-btn {
    button {
      background-color: $lightGreen;
      font-size: 16px;
      font-weight: 600;
      padding: 20px 18px;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    padding: 5rem 3rem 3rem;
  }
  .maternity-registration-btn {
    display: none;
  }
}
@media (max-width: 980px) {
  .header {
    padding: 1rem 1rem 0rem;
  }
}
</style>

